<template>
    <v-app style="background-color: #f3f6f9">
        <v-main>
            <nav-bar></nav-bar>
           
            <CreateManuiComp />
        </v-main>
    </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import Auth from '@/services/auth.service.js'
import CreateManuiComp from '@/components/seller/CreateManuiComp.vue'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        NavBar,
        CreateManuiComp,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        document.title = 'הקמת מנוי | כרטיסי חיוג '
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
}
</script>
<style>
/* .container1 {
        width: 1000px; 
        margin: 0 auto; */
/* display: flex; */
/* flex-direction: column; */
/* justify-content: center; */
/* align-items: center; */
/* height: 100vh;  */
/* } */
</style>