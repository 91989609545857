import ApiServices from "@/services/api.service";

class SharedFunctions {
    constructor() {
    }
  

    static async getPlans() {
      try {
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/plans/get_plans`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          return [];
        } else if (res.status === 200) {
          // console.log(jsonObject);
          return jsonObject;
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    }


  }
  export default SharedFunctions;
  
  