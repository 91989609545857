import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/PageNotFoundView.vue'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import CustomersView from '../views/CustomersView.vue'
import ManuimView from '../views/ManuimView.vue'
import ManuiPlansView from '../views/ManuiPlansView.vue'
import UsersView from '../views/UsersView.vue'
import CreateManuiView from '../views/seller/CreateManuiView.vue'
import PlansView from '../views/admin/PlansView.vue'
import LogsView from '../views/admin/LogsView.vue'
import EventsView from '../views/EventsView.vue'
import CallHistoryView from '../views/CallHistoryView.vue'
import RessetPassView from '../views/ResetPasswordView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersView,
  },
  {
    path: '/manuim/:id/:name',
    name: 'manuim',
    component: ManuimView,
  },
  {
    path: '/events/:id/:name',
    name: 'events',
    component: EventsView,
  },
  {
    path: '/callHistory/:id/:manui_number',
    name: 'callHistory',
    component: CallHistoryView,
  },
   {
    path: '/manui-plans/:id/:manui_number',
    name: 'manuiPlans',
    component: ManuiPlansView,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlansView,
  },
  // {
  //   path: '/communities',
  //   name: 'communities',
  //   component: CommunitiesView,
  // },
  {
    path: '/create-manui',
    name: 'createManui',
    component: CreateManuiView,
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
  },
 
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
