<template>
  <v-main>
    <v-app-bar flat style="background-color: #FFFFFF;" height="98">
      <v-toolbar-title style="height: 98px; margin-left: 10%;">
        <div class="iconNavBar">
          <v-img alt="Vuetify Logo" :src="logo ? logo : require('@/assets/logo.svg')" contain transition="scale-transition"
            style="height: 60%;" />
        </div>
      </v-toolbar-title>

      <v-toolbar-items class="ms-3" v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn v-for="(item, index) in menu" :key="item.id" text v-if="item.show" class="i"
          style="color: #6a707e; font-size: 16px; font-weight: bold;" :class="{ 'over-on-item': overOnItem === index }"
          @click="selectedItFunc(index, item.link)" @mouseover="addUnderlineNav(index)"
          @mouseout="removeUnderlineNav(index)">
          <div class="path-23-parent" style="padding-left: 25px; padding-right: 25px;">
            {{ item.title }}
          </div>
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <div class="d-flex justify-end pt-3 pl-3">
        <div class="user-info" style="display: flex; justify-content: center; align-items: center; margin-left: 40px;">
          <span class="mr-2" style="font-weight: 500; font-size: 16px; color: #6a707e;" >{{ userName }}</span>
          <span class="mr-2" style="font-size: 16px; color: #6a707e;" @click="showLogs()">{{ email }}</span>
        </div>

        <v-tooltip bottom v-if="isAdmin">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="swichToAdmin()" style="margin-left: 15px;">
              mdi-swap-horizontal
            </v-icon>
          </template>
          <span>חזור לניהול</span>
        </v-tooltip>

        <v-tooltip bottom v-if="isAgent">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="swichToAgent()" style="margin-left: 15px;">
              mdi-swap-horizontal
            </v-icon>
          </template>
          <span>חזור לסוכן</span>
        </v-tooltip>

        <div v-if="$vuetify.breakpoint.mdAndUp">
          <div class="logout-button-container" @mouseover="isFocused = true" @mouseleave="isFocused = false"
            @click="logOut()">
            <v-img src="@/assets/log-out.svg"
              :style="{ width: isFocused ? '40px' : '34px', height: isFocused ? '46px' : '40px', marginRight: '20px', marginLeft: '20px', marginTop: '5px' }"
              class="image-transition" />
          </div>
        </div>

      </div>

      <div v-if="$vuetify.breakpoint.mdAndDown">

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list>
              <v-list-item v-for="item in menu" :key="item.id" :to="item.link">
                <v-list-item-icon>
                  <div class="path-23-parent" style="margin-left: 25px; margin-right: 10px;"
                    v-if="item.icon === '/img/path-23.1e7b2424.svg'">
                    <!-- <img :src="item.icon" class="path-23-icon" /> -->
                    <div class="group-item"></div>
                    <div class="group-inner"></div>
                  </div>
                  <!-- <img v-else style="margin-left: 25px; margin-right: 10px;" alt="" :src="item.icon" /> -->
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list-item :key="'logout'" @click="logOut()">
              <v-list-item-action>
                <!-- <v-img src="@/assets/log-out.svg" /> -->
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

  </v-main>
</template>
  
<script>
import Auth from '@/services/auth.service.js'
import ApiServices from "@/services/api.service";

export default {
  props: {
    balanceChanged: Number,
  },
  data() {
    return {
      menu: [],
      sellerMenu: [
        { id: "home", title: "סטטיסטיקות", link: "/home", show: true },
        { id: "customers", title: "לקוחות", link: "/customers", show: true },
        { id: "create_manui", title: "הקמת מנוי", link: "/create-manui", show: true },
        // { id: "seller_account", title: "אזור אישי", link: "/seller_account", show: true},
      ],
      adminMenu: [
        { id: "home", title: "סטטיסטיקות", link: "/home", show: true },
        { id: "customers", title: "לקוחות", link: "/customers", show: true },
        { id: "users", title: "משתמשים", link: "/users", show: true },
        { id: "plans", title: "חבילות", link: "/plans", show: true },
        // { id: "communities", title: "קהילות", link: "/communities", show: true },
      ],
      agentMenu: [
        { id: "home", title: "סטטיסטיקות", link: "/home", show: true },
        { id: "customers", title: "לקוחות", link: "/customers", show: true },
        { id: "users", title: "משתמשים", link: "/users", show: true },
        // { id: "seller_account", title: "אזור אישי", link: "/seller_account", show: true},
      ],
      userName: "",
      email: "",
      role: "",
      isFocused: false,
      overOnItem: null,
      selectedNavItem: null,
      isAdmin: false,
      isAgent: false,
      logo: null,
    }

  },
  methods: {
    async seLogo() {
      try {
        if (localStorage.getItem("logo") !== "not found") {
          let token = localStorage.getItem("token");
          let api = process.env.VUE_APP_BASE_URL + `/users/get_logo`;
          const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
          const jsonObject = await res.json();
          if (res.status === 400) {
          } else if (res.status === 200) {
            if (jsonObject) {
              this.logo = process.env.VUE_APP_BASE_URL + jsonObject;
              localStorage.setItem("logo", this.logo);
            } else {
              localStorage.setItem("logo", "not found");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("logo");
      this.$router.replace({ name: "login" })
    },
    selectedItFunc(index, link) {
      this.$store.state.selectedNavItem = index;
      if (this.$route.path !== link) {
        this.$router.replace({ path: link })
      }
    },
    addUnderlineNav(index) {
      if (this.overOnItem !== index) {
        this.overOnItem = index;
      }
    },
    removeUnderlineNav() {
      this.overOnItem = this.$store.state.selectedNavItem;
    },
    setNavListIItems() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
      this.role = Auth.getUserRole();
      if (this.role === "admin") {
        this.menu = this.adminMenu;
      } else if (this.role === "agent") {
        this.menu = this.agentMenu;
      } else {
        this.menu = this.sellerMenu;
      }
      if (localStorage.getItem("tokenAdmin")) {
        this.isAdmin = true;
      } else if (localStorage.getItem("tokenAgent")) {
        this.isAgent = true;
      }
    },
    swichToAdmin() {
      localStorage.setItem("token", localStorage.getItem("tokenAdmin"));
      localStorage.removeItem("tokenAdmin");
      localStorage.removeItem("logo");
      this.isAdmin = false;
      if (localStorage.getItem("tokenAgent")) {
        localStorage.removeItem("tokenAgent");
      }
      this.$router.replace({ name: 'users' })
    },
    swichToAgent() {
      localStorage.setItem("token", localStorage.getItem("tokenAgent"));
      localStorage.removeItem("tokenAgent");
      localStorage.removeItem("logo");
      this.isAgent = false;
      this.$router.replace({ name: 'users' })
    },
    showLogs(){
      if (this.role === "admin") {
        this.$router.push({ name: 'logs'})
      }
    }
  },
  watch: {
  },
  created() {
    this.setNavListIItems();
    // הוספת קו תחתון במידה ועושים ריענון לדף
    this.menu.forEach((item, index) => {
      if (this.$route.path === item.link) {
        this.overOnItem = index;
        this.selectedNavItem = index;
      }
    });
    this.seLogo();
  },
}
</script>
<style>
.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}

.text-color-red {
  color: rgb(202, 4, 4) !important;
  font-weight: 700;
}

.text-color-grey {
  color: #6a707e
}

.image-transition {
  transition: width 0.3s ease, height 0.3s ease;
  /* Adjust the animation duration and easing as needed */
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px;
}

.iconNavBar {
  width: 320px;
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  height: 100%;
  /* Make sure the container takes full height */
}

/* הוספה בתוך אייקון של דוח טעינות */
.path-23-parent {
  position: relative;
  display: inline-block;
}

.path-23-icon {
  display: block;
}

.group-inner,
.group-item {
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 4px);
  background-color: #0089c3;
  width: 8px;
  height: 2px;
}

.group-inner {
  top: calc(50% + 2px);
  background-color: #131a39;
  width: 5px;
}

.over-on-item {
  border-bottom: 3px solid #0d2c6d;
}
</style>
