<template >
    <div>
        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">מנויים לקוח - {{ customerName }}</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ מנויים
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ filteredItems.length }}
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="paginatedItems" hide-default-footer
                            :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <v-icon :color="getIconColorForStatus(item.status)">
                                    {{ getIconForStatus(item.status) }}
                                </v-icon>
                                <span style="margin-right: 10px;">{{ item.status ? "פעיל" : "לא פעיל" }}</span>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <!-- Calculate the correct index based on the current page and items per page -->
                                    <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" @click="showManuiPlans(item)"
                                            style="margin-left: 17px;">
                                            mdi-eye-outline
                                        </v-icon>
                                    </template>
                                    <span>צפייה בחבילות</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" @click="showCallHistory(item)"
                                            style="margin-left: 17px;">
                                            mdi-phone-clock
                                        </v-icon>
                                    </template>
                                    <span>צפייה בהסטוריית שיחות</span>
                                </v-tooltip>

                                <v-tooltip bottom v-if="isAdmin">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="Math.ceil(filteredItems.length / itemsPerPage)"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            :customerId="customerId" @itemEdited="editedItemSaved" />

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import Auth from '@/services/auth.service';
import AddEditDialog from '@/components/manuim/dialogs/AddEditDialog'

export default {

    components: {
        SnackBar,
        AddEditDialog
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'מנוי', value: 'manui_number' },
            { text: 'סטטוס', value: 'status' },
            { text: 'נוצר בתאריך', value: 'createdAt' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        overlay: false,
        manuim: [],
        addEditDialog: false,
        formTitle: "",
        editedItem: {},
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        itemsPerPage: 20,
        customerId: "",
        customerName: "",
        isAdmin: false
    }),
    computed: {
        filteredItems() {
            if (!this.search) {
                return this.manuim;
            }
            return this.manuim.filter(item => {
                return Object.values(item).some(value => value.toString().toLowerCase().includes(this.search.toLowerCase()));
            });
        },
        paginatedItems() {
            // Apply pagination to filteredItems
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredItems.slice(start, end);
        }
    },
    methods: {
        async getCustomerManuim() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ customer_id: this.customerId });
                let api = process.env.VUE_APP_BASE_URL + `/manuim/get_customer_manuim`;
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                console.log(jsonObject);
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.manuim = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.manuim.forEach(manui => {
                manui.createdAt = Utils.formatDateString(manui.createdAt)
            });
        },
        getIconForStatus(status) {
            switch (status) {
                case true: return 'mdi-check-circle';
                case false: return 'mdi-cancel';
            }
        },
        getIconColorForStatus(status) {
            switch (status) {
                case true: return 'rgb(13, 138, 13)';
                case false: return 'rgb(206, 18, 18)';
                default: return '';
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openEditDialog(item, index) {
            this.editedItem = item;
            this.formTitle = "ערוך פרטי מנוי"
            this.addEditDialog = true
        },
        editedItemSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.manuim.findIndex(manui => manui._id === item._id);
            Object.assign(this.manuim[index], item);
        },
        showManuiPlans(manui) {
            this.$router.push({ name: "manuiPlans", params: { id: manui._id, manui_number: manui.manui_number} })
        },
        showCallHistory(manui) {
            this.$router.push({ name: "callHistory", params: { id: manui._id, manui_number: manui.manui_number} })
        },
    },
    created() {
        this.customerName = this.$route.params.name;
        this.customerId = this.$route.params.id;
        this.getCustomerManuim();
        this.isAdmin = Auth.getUserRole() === "admin" ? true : false;
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>
    