<template >
  <v-container style="margin-top: 90px; margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="auto" style="width: 576px; max-width: 100%;">
        <div id="rectangle_5">
          <v-card elevation="4" style="width: 100%; flex: 1; min-height: 0;">
            <v-img :src="require('@/assets/logo.svg')" class="mx-auto" contain
              :style="{ 'width': isResponsive ? '60%' : '40%' }" height="200" />
            <div>
            </div>
            <v-form @submit.prevent="submitHandler" ref="form" v-if="codeSent === false">
              <div class="textFiledPadd" style="padding-bottom: 0px;">
                <v-text-field class="textField" v-model="email" label="אימייל" type="text"
                  :rules="[v => !!v || 'שדה חובה']" placeholder="אימייל" outlined dense></v-text-field>
              </div>
              <div class="textFiledPadd">
                <v-text-field v-model="password" width="600" class="textField" outlined dense label="סיסמה"
                  type="password" :rules="[v => !!v || 'שדה חובה']" placeholder="סיסמה"></v-text-field>
                <span @click="forgotPassword">
                  <a style="color: #0d2c6d;">שכחתי סיסמה</a>
                </span>
              </div>
              <v-card-actions class="justify-center">
                <v-btn @click="login" color="#0d2c6d" width="243" max-width="100%" height="48" rounded class="mx-auto">
                  <span class="white--text" style="font-weight: bold; font-size: 18px; ">התחברות</span>
                </v-btn>
              </v-card-actions>
              <v-row v-if="progressShow" class="my-5">
                <v-col class="d-flex justify-center">
                  <v-progress-circular indeterminate color="#0099cd"></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
            <v-form @submit.prevent="submitHandler" ref="form" v-else>
              <div class="textFiledPadd">
                <v-text-field v-model="emailCode" ref="otpField" label="קוד אימות" class="textField" outlined dense
                  type="text" :rules="[v => !!v || 'שדה חובה']" placeholder="קוד אימות" @keypress="validateNumericInput"
                  @keydown.enter="handleEnterKeyOtp"></v-text-field>
              </div>
              <v-card-actions class="justify-center">
                <v-btn @click="emailVerify" color="#0d2c6d" width="243" max-width="100%" height="48" rounded
                  class="mx-auto">
                  <span class="white--text" style="font-weight: bold; font-size: 18px;">אמת קוד</span>
                </v-btn>
              </v-card-actions>
              <v-row v-if="progressShow" class="my-5">
                <v-col class="d-flex justify-center">
                  <v-progress-circular indeterminate color="#0099cd"></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {

  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    email: "",
    password: "",
    emailCode: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    codeSent: false,
  }),
  methods: {

    async login() {

      if (this.$refs.form.validate()) {

        this.progressShow = true
        let MyJSON = JSON.stringify({ email: this.email, password: this.password });
        let api = process.env.VUE_APP_BASE_URL + "/auth/login"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status === 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 200) {
            this.showSnackBar("אימייל לאימות נשלח בהצלחה!", "green");
            this.codeSent = true;
            setTimeout(() => {
              this.$refs.otpField.focus();
            }, 200);
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }
      }
    },

    async emailVerify() {
      if (this.$refs.form.validate()) {

        this.progressShow = true
        let MyJSON = JSON.stringify({ email: this.email, emailCode: this.emailCode });
        let api = process.env.VUE_APP_BASE_URL + "/auth/verify_code"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          const jsonObject = await response.json();
          if (response.status === 400) {
            this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
          } else if (response.status === 200) {
            this.showSnackBar("התחברת בהצלחה!", "green");
            localStorage.setItem('token', jsonObject.token);
            let role = Auth.getUserRole();
            this.$router.replace({ name: "home" })
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },
    validateNumericInput(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async forgotPassword() {
      if (this.email === "") {
        this.showSnackBar("יש להזין אימייל לצורך איפוס הסיסמה", "red");
        return
      }
      this.progressShow = true;
      let MyJSON = JSON.stringify({ email: this.email });
      let api = process.env.VUE_APP_BASE_URL + "/auth/forgotPassword"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;
        if (response.status === 401) {
          this.showSnackBar("שגיאה: אימייל לא קיים", "red");
        } else if (response.status === 500) {
          this.showSnackBar("שגיאת שרת", "red");
        } else if (response.status === 200) {
          const jsonObject = await response.json();
          if (jsonObject.emailSent) {
            this.showSnackBar("נשלח מייל לאיפוס הסיסמה", "green");
          } else {
            this.showSnackBar("שגיאה בשליחת מייל איפוס", "red");
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error Login: " + error, "red");
      }
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    handleEnterKeyOtp(event) {
      if (event.keyCode === 13) {
        this.emailVerify(); // Trigger the button click event
      }
    },
  },
  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL'
    },
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    document.title = 'Login | CLICK-CALL'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
@media (min-width: 501px) {
  .textFiledPadd {
    padding: 30px;
  }
}

@media (max-width: 501px) {
  .textFiledPadd {
    padding: 5px;
  }
}

#rectangle_5 {
  padding: 10px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 8px solid #0d2c6d;
  background: rgba(255, 255, 255, 0);
  width: 576px;
  max-width: 100% !important;
  /* height: 750px; */
  min-height: 570px;
  display: flex;
  flex-direction: column;
}

.textField {
  height: 52px !important;
}
</style>
