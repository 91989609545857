<template>
    <v-app style="background-color: #f3f6f9">
        <v-main>
            <nav-bar></nav-bar>
           
            <CallHistoryComp />

        </v-main>
    </v-app>
</template>
<script>
import NavBar from '@/components/widgets/navBar.vue'
import Auth from '@/services/auth.service.js'
import CallHistoryComp from '@/components/callHistory/CallHistoryComp.vue'

export default {
    components: {
        NavBar,
        CallHistoryComp
    },
    mounted() {
        document.title = 'הסטוריית שיחות | כרטיסי חיוג'
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
}
</script>
<style>
</style>