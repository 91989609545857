<template >
    <div>
        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">חבילות מנוי - {{ manuiNumner }}</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ חבילות
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ filteredItems.length }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="2" style="margin-top: 1%;">
                        <v-select hide-spin-buttons hide-details label="סינון לפי" flat solo :items="typesList"
                            v-model="filterType" :item-text="'hebrewName'" :item-value="'value'"
                            class="text_fields"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="filteredItems" hide-default-footer
                            sort-by="createdAt" sort-desc
                            :search="search" :page.sync="currentPage" :items-per-page="itemsPerPage" @page-count="pageCount = $event">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <v-icon :color="getIconColorForStatus(item.status)">
                                    {{ getIconForStatus(item.status) }}
                                </v-icon>
                                <span style="margin-right: 10px;">{{ item.status === "active" ? "פעיל" : item.status === "inactive" ? "לא פעיל" : 
                                item.status === "expired" ? "פג תוקף" : "הסתיים" }}</span>
                            </template>

                            <template v-slot:item.index="{item, index }">
                                <tr>
                                    <td>{{ item.integer_id }}</td>
                                </tr>
                            </template>

                            <!-- <template v-slot:item.seconds_left="{item, index }">
                                <tr>
                                    <td>{{ (item.seconds_left / 60).toFixed(1) }}</td>
                                </tr>
                            </template> -->

                            <template v-slot:item.renew="{ item, index }">
                               <span>{{ item.renew ? 'כן' : 'לא' }}</span>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom v-if="isAdmin">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="pageCount"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @itemEdited="editedItemSaved" />

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import Auth from '@/services/auth.service';
import AddEditDialog from '@/components/manuiPlans/dialogs/AddEditDialog'

export default {

    components: {
        SnackBar,
        AddEditDialog
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'שם החבילה', value: 'plan_id.name' },
            { text: 'דקות שיחה', value: 'plan_id.minutes' },
            { text: 'דקות חריגה', value: 'plan_id.exceeding_minutes' },
            { text: 'יתרה לניצול (בשניות)', value: 'seconds_left' },
            { text: 'סטטוס', value: 'status' },
            { text: 'מנוי מתחדש', value: 'renew' },
            { text: 'תאריך רכישה', value: 'createdAt' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        overlay: false,
        manuiPlans: [],
        addEditDialog: false,
        formTitle: "",
        editedItem: {},
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        pageCount: 0,
        itemsPerPage: 20,
        manuiId: "",
        manuiNumner: "",
        filterType: "all",
        typesList: [{ hebrewName: "הצג הכל", value: "all" }, { hebrewName: "פעילים", value: "active" }, { hebrewName: "לא פעילים", value: "inactive" }
            , { hebrewName: "הסתיימו", value: "finished" }, { hebrewName: "פג תוקף", value: "expired" }],
        isAdmin: false
    }),
    computed: {
        filteredItems() {

            if (this.filterType !== "all") {
                return this.manuiPlans.filter(
                    (plan) => plan.status === this.filterType
                );
            }
            return this.manuiPlans;
        }
    },
    methods: {
        async getManuiPlans() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ manui_id: this.manuiId });
                let api = process.env.VUE_APP_BASE_URL + `/manui_plans/get_manui_plans`;
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                console.log(jsonObject);
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.manuiPlans = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.manuiPlans.forEach(plan => {
                plan.createdAt = Utils.formatDateString(plan.createdAt)
            });
        },
        getIconForStatus(status) {
            switch (status) {
                case 'active': case 'finished': return 'mdi-check-circle-outline';
                case 'inactive': return 'mdi-cancel';
                case 'expired': return 'mdi-calendar-remove';
                default: return '';
            }
        },
        getIconColorForStatus(status) {
            switch (status) {
                case 'active': return 'rgb(13, 138, 13)';
                case 'inactive': return 'rgb(206, 18, 18)';
                case 'finished': return 'rgb(207, 142, 20)';
                case 'expired': return 'rgb(207, 89, 20)';
                default: return '';
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openEditDialog(item, index) {
            this.editedItem = item;
            this.formTitle = "ערוך פרטי חבילה"
            this.addEditDialog = true
        },
        editedItemSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.manuiPlans.findIndex(plan => plan._id === item._id);
            Object.assign(this.manuiPlans[index], item);
        },
    },
    created() {
        this.manuiNumner = this.$route.params.manui_number;
        this.manuiId = this.$route.params.id;
        this.getManuiPlans();
        this.isAdmin = Auth.getUserRole() === "admin" ? true : false;
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>
    