import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        selectedNavItem: null,
        colorPrimary: "#3F51B5",
        colorSecondary: "#5C6BC0",
        colorTertiary: "#E8EAF6"
    }
})