<template >
  <div>
    <div style="
        height: 50px;
        background-color: #dae1eb;
        display: flex;
        justify-content: center;
        align-items: center;
      ">
      <span style="font-size: large; color: #0d2c6d">הקמת מנוי</span>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 100px;
        margin-top: 50px;
      ">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10" lg="7" style="background-color: white; border-radius: 5px; padding: 20px">
          <v-card class="mx-auto" outlined>
            <div v-if="!isDone">
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1" color="#0d2c6d">פרטים אישיים</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 2" step="2" color="#0d2c6d">חבילה</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 3" step="3" color="#0d2c6d">פרטי תשלום</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4" color="#0d2c6d">פרטי סים</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form ref="form1">
                      <v-row class="my-5 mx-5 d-none d-md-flex">
                        <v-col cols="12" md="4" style="display: flex;">
                          <v-text-field v-if="!customerExist" label="תעודת זהות" hide-details dense type="number"
                            @keydown.enter="handleEnterKeyOtp" hide-spin-buttons outlined
                            v-model="customerDetails.card_id" :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>

                          <v-btn v-if="!customerExist" dark color="#8066f0" style="margin-right: 15px;"
                            @click="checkId">אישור</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="showFields" class="my-5 mx-5 d-none d-md-flex">
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="שם פרטי" flat outlined dense required v-model="customerDetails.name"
                            :disabled="customerExist" :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="משפחה" outlined dense required v-model="customerDetails.family"
                            :disabled="customerExist"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="שם לחשבונית" outlined dense required :disabled="customerExist"
                            v-model="customerDetails.business_name" :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                     
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="טלפון" type="number" hide-spin-buttons outlined dense
                            :disabled="customerExist" v-model="customerDetails.phone1" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="טלפון בית" type="number" hide-spin-buttons outlined
                            :disabled="customerExist" v-model="customerDetails.phone2" dense required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="אימייל" outlined dense v-model="customerDetails.email"
                            :disabled="customerExist" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="רחוב" outlined dense required v-model="customerDetails.address"
                            :disabled="customerExist"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="מספר בית" type="number" hide-spin-buttons outlined
                            :disabled="customerExist" v-model="customerDetails.building" dense required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="עיר" outlined dense required v-model="customerDetails.city"
                            :disabled="customerExist"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" style="padding-bottom: 0px; padding-top: 0px">
                          <v-text-field label="מיקוד" type="number" hide-spin-buttons outlined dense
                            :disabled="customerExist" v-model="customerDetails.postal_code" required></v-text-field>
                        </v-col>
                      </v-row>
                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="validateAndNext(2, 'form1')">{{ "< " }} שלב הבא</v-btn
                                                                                        >
                                                                                      </div>
                                                                                    </v-form>
                                                                                  </v-stepper-content>


                            <v-stepper-content step="2">
                            <v-form ref="form2">
                              <div class="padding-responsive">
                                <div class="padding-responsive">
                                  <span style="font-size: 24px; color: #6A707E;">בחר חבילה</span>
                                  <v-row class="margin-responsive">
                                    <v-col cols="auto" md="6" v-for="(item, index) in plans">

                                      <div class="box_credit_buy_package"
                                        style="width: 350px; max-width: 100%; background-color: white;">

                                        <div
                                          style="padding: 15px; display: flex; justify-content: center; background-color: #F3F6F9; ">
                                          <span :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"
                                            style="font-size: 22px;">{{ item.name
                                            }}</span>
                                        </div>

                                        <div :style="{ backgroundColor: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"
                                          style="height: 2px;"></div>

                                        <div style="margin: 10px;">
                                          <div style="display: flex; justify-content: center;">
                                            <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל דקות
                                              בחבילה:
                                              <strong>{{ item.minutes }}</strong>
                                            </span>
                                          </div>
                                          <div style="display: flex; justify-content: center;">
                                            <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל דקות
                                              חריגה
                                              בחבילה: <strong>{{
                                                item.exceeding_minutes }}</strong></span>
                                          </div>

                                          <div style="display: flex; justify-content: center;">
                                            <span
                                              style="margin-top: 10px; height: 50px; font-size: 14px; color: #6A707E;">
                                              {{
                                                item.details }}</span>
                                          </div>

                                          <div style="display: flex; justify-content: center;">
                                            <span style="margin-top: 10px; font-size: 50px;"
                                              :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"><span
                                                style="font-size: medium; font-weight: 600;">₪</span>{{ item.price
                                                }}</span>
                                          </div>

                                          <div v-if="hasSetupFee > 0" style="display: flex; justify-content: center;">
                                            <div><span style="font-size: small; color: #8066f0;">דמי הקמת מנוי:
                                              </span>
                                            </div>
                                          </div>
                                          <div v-if="hasSetupFee > 0" style="display: flex; justify-content: center;">
                                            <div><span style="font-weight: bold; color: #8066f0;"> {{ hasSetupFee + ""
                                            }} ₪</span></div>
                                          </div>
                                        </div>

                                        <div style="display: flex; justify-content: center; padding-bottom: 10px!important;">
                                          <v-btn color="#0d2c6d" :outlined="clickedButtonIndex !== index"
                                            :dark="clickedButtonIndex === index" style="width: 150px;"
                                            @click="buttonClicked(index, item._id)">
                                            לרכישה
                                          </v-btn>
                                        </div>

                                      </div>


                                    </v-col>
                                  </v-row>

                                </div>
                              </div>
                              <div style="direction: ltr">
                                <v-btn color="#8066f0" dark @click="validateAndNext(3, 'form2')"
                                  style="margin-right: 15px">{{ "< " }} שלב הבא</v-btn>
                                                            <v-btn color=" #eee" @click="e1 = 1">שלב קודם{{ " > "
                                                            }}</v-btn>
                              </div>
                            </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-form ref="form3">
                      <v-row v-if="!customerDetails.last_digits">
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field label="מספר כרטיס" type="number" hide-spin-buttons outlined dense
                            style="margin-top: 10px;" v-model="payDetails.card_number" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                          <v-text-field label="תוקף" type="number" hide-spin-buttons outlined v-model="payDetails.tokef"
                            dense required :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                          <v-text-field label="ספרות בטיחות" type="number" hide-spin-buttons outlined
                            v-model="payDetails.cvv" dense required :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-checkbox :label="'אשראי מספר: ' + customerDetails.last_digits" v-model="isChecked" disabled
                            selected hide-details></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>

                      <v-row v-if="operateByAdmin" style="margin-bottom: 10px;">
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-checkbox :label="'דלג על J5'" v-model="adminSkipJ5" hide-details></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>
                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="validateAndNext(4, 'form3')" style="margin-right: 15px">{{ "<"
                        }} שלב הבא</v-btn>
                            <v-btn color="#eee" @click="e1 = 2">שלב קודם{{ " >" }}</v-btn>
                      </div>
                    </v-form>
                  </v-stepper-content>


                  <v-stepper-content step="4">
                    <v-form ref="form4">
                      <v-row>
                        <v-col cols="12" sm="3"> </v-col>
                        <v-col cols="12" sm="6">
                          <v-select label="בחר סוג מנוי" dense :items="selectTypeList" outlined v-model="manuiDetails.manuiType"
                            style="margin-top: 10px;" required :rules="[(v) => !!v || 'שדה חובה']"></v-select>
                            <v-text-field v-if="manuiDetails.manuiType === 'מנוי חדש'" label="מספר מנוי" type="number"
                            hide-spin-buttons outlined dense v-model="manuiDetails.manuiNumber" required
                            :rules="[(v) => !!v || 'שדה חובה']"></v-text-field>
                            <v-select v-if="manuiDetails.manuiType === 'מנוי קיים'" label="מספר מנוי" dense :items="selectManuiList" outlined v-model="manuiDetails.manuiNumber"
                            style="margin-top: 10px;" required :rules="[(v) => !!v || 'שדה חובה']"></v-select>

                        </v-col>
                        <v-col cols="12" sm="3"> </v-col>
                      </v-row>
                      <div style="direction: ltr">
                        <v-btn color="#8066f0" dark @click="saveNewManui" style="margin-right: 15px">סיום</v-btn>
                        <v-btn color="#eee" @click="e1 = 3">שלב קודם{{ " >" }}</v-btn>
                      </div>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
            <div v-else style="text-align: center;">
              <span style="font-size: xx-large; color: rgba(14, 131, 14, 0.685);">המנוי נוצר בהצלחה!</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <template>
      <v-overlay :value="progressShow">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>
    
<script>
import SnackBar from "@/components/widgets/snackBar";
import ApiServices from "@/services/api.service";
import Utils from "@/util/utils";
import Auth from "@/services/auth.service";
import SharedFunctions from "@/shared/SharedFunctions";

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    search: "",
    customerDetails: {
      name: "",
      family: "",
      business_name: "",
      card_id: "",
      phone1: "",
      phone2: "",
      email: "",
      address: "",
      building: "",
      city: "",
      postal_code: "",
      last_digits: "",
    },
    payDetails: {
      card_number: "",
      tokef: "",
      cvv: "",
    },
    manuiDetails: {
      manuiType: "",
      manuiNumber: "",
    },
    plans: [],
    constants: [],
    progressShow: false,
    e1: 1, // This keeps track of the active step
    customerExist: false,
    showFields: false,
    selectedPlan: "",
    existCustomers: [], // Items for v-select
    clickedButtonIndex: null,
    selectTypeList: ["מנוי חדש", "מנוי קיים"], // Items for v-select
    selectManuiList: [], // Items for v-select
    hasSetupFee: 0,
    isChecked: true, // Controls the checkbox state
    isDone: false,
    adminSkipJ5: false,
    operateByAdmin: false,
  }),
  computed: {},
  methods: {
    validateAndNext(step, formNum) {
      if (this.$refs[formNum].validate()) {
        if (formNum === "form1") {
          const customerExist = this.existCustomers.some(customer => customer.card_id === this.customerDetails.card_id)
          if (customerExist && !this.customerExist) {
            return this.showSnackBar("הלקוח קיים, יש ללחוץ על אישור", "red");
          }
        }
        if (formNum === "form2") {
          if (!this.selectedPlan) {
            return this.showSnackBar("נא לבחור חבילה", "red");
          }
        }
        this.e1 = step; // Move to the next step
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    handleEnterKeyOtp(event) {
      if (event.keyCode === 13) {
        this.checkId(); // Trigger the button click event
      }
    },
    getItemText(item) {
      return `${item.level_name} (${item.level_code})`;
    },
    async getExistCustomers() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/customers/get_customers_limit_details`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.existCustomers = jsonObject.map(item => ({ card_id: item.card_id, fullObject: item }));
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    async getSingleCustomer() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const myJSON = JSON.stringify({ customer_card_id: this.customerDetails.card_id });
        let api = process.env.VUE_APP_BASE_URL + `/customers/get_single_customer`;
        const res = await fetch(api, ApiServices.requestOptions("POST", myJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
          return null;
        } else if (res.status === 200) {
          return jsonObject;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get customer: " + error, "red");
        return null;
      }
    },
    async getDetails() {
      this.plans = await SharedFunctions.getPlans();
    },
    async saveNewManui() {
      try {
        if (this.$refs.form4.validate()) {
          this.progressShow = true;
          let token = localStorage.getItem("token");
          // this token for skip J5 cardcome
          let adminToken = localStorage.getItem("tokenAdmin") || null;
          // Prepare FormData
          let formData = new FormData();
          formData.append("adminToken", JSON.stringify(adminToken)); // Assuming `imageFile` is the image to be uploaded
          formData.append("skipJ5", JSON.stringify(this.adminSkipJ5)); // Assuming `imageFile` is the image to be uploaded
          formData.append("customerExist", JSON.stringify(this.customerExist)); // Append each detail from `otherDetails`
          formData.append("seller_id", Auth.getUserId()); // Append each detail from `otherDetails`
          formData.append("customer", JSON.stringify(this.customerDetails)); // Append each detail from `otherDetails`
          formData.append("cardDetail", JSON.stringify(this.payDetails)); // Append each detail from `otherDetails`
          formData.append("manuiDetails", JSON.stringify(this.manuiDetails)); // Append each detail from `otherDetails`
          formData.append("planDetails", JSON.stringify(this.selectedPlan)); // Append each detail from `otherDetails`

          // Adjust requestOptions for FormData and include the token
          let requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, // Adjust as needed
            },
            body: formData,
          };

          let api = process.env.VUE_APP_BASE_URL + `/customers/create_customer_and_manui`;
          const res = await fetch(api, requestOptions);
          const jsonObject = await res.json();
          this.progressShow = false;
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.isDone = true;
            setTimeout(() => {
              this.$router.replace({ name: "home" });
            }, 2000);
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    planChosed(planId) {
      this.selectedPlan = this.plans.find(plan => plan._id === planId);
    },
    buttonClicked(index, itemId) {
      // Update the clicked button's index
      this.clickedButtonIndex = index;
      // Now call your planChosen function or whatever you need to do when a button is clicked
      this.planChosed(itemId);
    },
    async checkId() {
      if (!this.customerDetails.card_id) {
        return;
      }

      let searchId;
      let existCust = this.existCustomers.find(customer => customer.card_id === this.customerDetails.card_id);
      if (!existCust) {
        // Check if card_id starts with "0"
        if (this.customerDetails.card_id.startsWith("0")) {
          // Remove the leading "0" for the search
          searchId = this.customerDetails.card_id.substring(1);
        } else {
          // Add a "0" at the beginning for the search
          searchId = "0" + this.customerDetails.card_id;
        }
        // Search for the customer with the modified card_id
        existCust = this.existCustomers.find(customer => customer.card_id === searchId);
      }

      const isValidCardId = this.is_israeli_id_number(this.customerDetails.card_id);
      if (!isValidCardId) {
        return this.showSnackBar("תעודת הזהות אינה תקינה", "red");
      }

      this.showFields = true
      if (existCust) {

        this.customerDetails = await this.getSingleCustomer(existCust.card_id);
        console.log(this.customerDetails);
        this.selectManuiList = this.customerDetails.manuimDetails.map(manui => manui.manui_number);
        this.customerExist = true;
      } else {
        this.customerDetails = { card_id: this.customerDetails.card_id };
        this.customerExist = false;
      }
    },
    is_israeli_id_number(id) {
      id = String(id).trim();
      if (id.length > 9 || isNaN(id)) return false;
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
    },
  },
  created() {
    this.getExistCustomers();
    this.getDetails();
    this.hasSetupFee = Auth.getUserSetupFee();

    let adminToken = localStorage.getItem("tokenAdmin") || null;
    if (adminToken) {
      this.operateByAdmin = true;
    }
  },
};
</script>
<style scoped>
.gradient-button-background {
  background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.box_credit_buy_package {
  box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px 4px 8px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
}


.padding-responsive {
  padding: 10px;
}

@media (min-width: 600px) {
  /* .padding-responsive {
    padding: 30px;
  } */

  .margin-responsive {
    margin: 10px;
  }
}
</style>
    

<!-- let api = process.env.VUE_APP_BASE_URL + `/manuim/create_manui`;
const response = await axios.post(api, formData, {
  headers: {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'multipart/form-data'
  }
});
if (response.status === 400) {
  this.showAllertMessage("שגיאה: לא ניתן להעלות  קובץ ", "rgb(150, 19, 19)");
} else if (response.status === 200) {
  this.progressShow = false;
  this.showSnackBar("בוצע בהצלחה", "red");
} -->