<template >
    <div>
        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">יומן אירועים - {{ customerName }}</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px; text-align: center;">
                <v-row>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ אירועים
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ filteredItems.length }}
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="paginatedItems" hide-default-footer sort-by="createdAt" sort-desc
                            :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <!-- Calculate the correct index based on the current page and items per page -->
                                    <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" circle :length="Math.ceil(filteredItems.length / itemsPerPage)"
                            :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import Auth from '@/services/auth.service';

export default {

    components: {
        SnackBar,
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index' },
            { text: 'סוג אירוע', value: 'type' },
            { text: 'באמצעות', value: 'by_platform' },
            { text: 'על ידי', value: 'by_user' },
            { text: 'פרטי האירוע', value: 'details' },
            { text: 'נוצר בתאריך', value: 'createdAt' },
        ],
        overlay: false,
        events: [],
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        itemsPerPage: 20,
        customerId: "",
        customerName: "",
        isAdmin: false
    }),
    computed: {
        filteredItems() {
            if (!this.search) {
                return this.events;
            }
            return this.events.filter(item => {
                return Object.values(item).some(value => value.toString().toLowerCase().includes(this.search.toLowerCase()));
            });
        },
        paginatedItems() {
            // Apply pagination to filteredItems
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredItems.slice(start, end);
        }
    },
    methods: {
        async getCustomerEvents() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ customer_id: this.customerId });
                let api = process.env.VUE_APP_BASE_URL + `/events/get_customer_events`;
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                console.log(jsonObject);
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.events = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.events.forEach(evt => {
                evt.createdAt = Utils.formatDateString(evt.createdAt)
            });
        },
        getIconForStatus(status) {
            switch (status) {
                case true: return 'mdi-check-circle';
                case false: return 'mdi-cancel';
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    created() {
        this.customerName = this.$route.params.name;
        this.customerId = this.$route.params.id;
        this.getCustomerEvents();
        // this.isAdmin = Auth.getUserRole() === "admin" ? true : false;
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}

/* .v-data-table__wrapper table {
    border-collapse: collapse;
}

.v-data-table__wrapper td,
.v-data-table__wrapper th {
    border: none;
} */

/* ::v-deep table {
  border-collapse: separate !important;
  border-spacing: 0 10px!important 
} */
</style>
    