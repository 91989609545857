<template >
    <div>

        <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
            <span style="font-size: large; color: #0d2c6d;">משתמשים</span>
        </div>

        <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 100px; margin-top: 50px;">
            <div style="background-color: white; width:90%; border-radius: 20px; padding: 20px;">
                <v-row>
                    <v-col cols="12" md="2" style="margin-top: 1%; display: flex; align-items: center;">
                        <div style="width: 100%;">
                            <v-btn color="black" dark @click="openCreateDIalog()">הוסף משתמש חדש</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%;">
                        <v-text-field hide-spin-buttons hide-details label="חיפוש" flat solo v-model="search"
                            class="text_fields"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top: 1%; display: flex; align-items: center;">
                        <div>
                            <span style="color: #bbbaba; font-weight: 600;">
                                סה"כ משתמשים:
                            </span>
                        </div>
                        <div style="margin-right: 10px; color: #bbbaba; font-weight: 600;">
                            {{ filteredItems.length }}
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-data-table id="table1" :headers="headers" :items="paginatedItems" hide-default-footer
                            :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                            <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                                <span class="table-headers-span">{{ header.text }}</span>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <!-- Calculate the correct index based on the current page and items per page -->
                                    <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
                                    <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:item.actions="{ item, index }" >
                                <v-tooltip bottom v-if="item.role !== 'מנהל'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" @click="switchToUser(item)"
                                            style="margin-left: 15px;">
                                            mdi-login-variant
                                        </v-icon>
                                    </template>
                                    <span>כניסה למערכת</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium style="margin-left: 15px;"
                                            @click="openEditDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom>

                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#808080" medium class="me-3"
                                            @click="openDeleteDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                        <v-pagination v-model="currentPage" color="#8066f0" circle
                            :length="Math.ceil(filteredItems.length / itemsPerPage)" :total-visible="7"></v-pagination>

                    </v-col>
                </v-row>

            </div>
        </div>
        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>
        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @userAdded="newUserSaved" @userEdited="editedUserSaved"> </AddEditDialog>

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @userDeleted="userDeleted">
        </AlertDialog>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";
import AddEditDialog from '@/components/users/dialogs/AddEditDialog'
import AlertDialog from '@/components/users/dialogs/AlertDialog'
import Auth from '@/services/auth.service.js'

export default {

    components: {
        SnackBar,
        AddEditDialog,
        AlertDialog,
    },
    data: () => ({
        headers: [
            { text: '#', value: 'index', align: "center" },
            { text: 'שם', value: 'name', align: "center" },
            { text: 'טלפון', value: 'phone', align: "center" },
            { text: 'ת.ז/ח.פ', value: 'card_id', align: "center" },
            { text: 'שם החנות', value: 'shop_name', align: "center" },
            { text: 'מיקום החנות', value: 'shop_address', align: "center" },
            { text: 'סטטוס', value: 'status', align: "center" },
            { text: 'הרשאות', value: 'role', align: "center" },
            { text: 'משוייך לסוכן', value: 'agent_id.name', align: "center" },
            { text: 'פעולות', value: 'actions', align: "center" },
        ],
        overlay: false,
        alertDialog: false,
        addEditDialog: false,
        formTitle: "",
        users: [],
        editedItem: {},
        itemToDelete: {},
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        search: "",
        progressShow: false,
        currentPage: 1,
        itemsPerPage: 20,
    }),
    computed: {
        filteredItems() {
            if (!this.search) {
                return this.users;
            }
            return this.users.filter(item => {
                return Object.values(item).some(value => value && value.toString().toLowerCase().includes(this.search.toLowerCase()));
            });
        },
        paginatedItems() {
            // Apply pagination to filteredItems
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredItems.slice(start, end);
        }
    },
    methods: {
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getUsers() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/users/get_users`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status === 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.users = jsonObject;
                    this.formatDetails();
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.users.forEach(user => {
                // user.createdAt = Utils.formatDateString(call.createdAt)
                user.status = user.status ? "פעיל" : "לא פעיל";
                user.role = user.role === "admin" ? "מנהל" : user.role === "agent" ? "סוכן" : "משווק";
            });
        },
        openEditDialog(item, index) {
            const originalItem = this.users.find(user => user._id === item._id);
            this.editedItem = originalItem;
            this.formTitle = "ערוך משתמש"
            this.addEditDialog = true
        },

        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.formTitle = "האם אתה בטוח שברצונך למחוק את המשתמש?"
            this.alertDialog = true
        },
        openCreateDIalog() {
            this.formTitle = "הוסף משתמש"
            this.editedItem = {}
            this.addEditDialog = true
        },
        newUserSaved(item, text, color) {

            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.users.push(item);

        },
        editedUserSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.users.findIndex(user => user._id === item._id);
            item.status = item.status ? "פעיל" : "לא פעיל";
            item.role = item.role === "admin" ? "מנהל" : item.role === "agent" ? "סוכן" : "משווק";
            Object.assign(this.users[index], item);
        },
        userDeleted(item, text, color) {
            if (!item) {
                this.showSnackBar(text, color)
                return
            }
            const index = this.users.findIndex(user => user._id === item._id);
            this.users.splice(index, 1);
            this.showSnackBar(text, color)
        },
        async switchToUser(item) {
            this.progressShow = true
            let token = localStorage.getItem("token");
            const role = this.getUserRole();
            localStorage.removeItem("logo");
            if (role === "admin") {
                localStorage.setItem('tokenAdmin', token);
            }else if(role === "agent"){
                localStorage.setItem('tokenAgent', token);
            }else{ return}
            let MyJSON = JSON.stringify({ _id: item._id });
            let api = process.env.VUE_APP_BASE_URL + "/users/swich_to_user"

            try {
                const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                this.progressShow = false;
                const jsonObject = await response.json();
                if (response.status === 400) {
                    this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
                } else if (response.status === 200) {
                    localStorage.setItem('token', jsonObject.token);
                    this.$router.replace({ name: "home" })
                }
            } catch (error) {
                this.showSnackBar("Error Login: " + error, "red");
            }
        },
        getUserRole(){
            return Auth.getUserRole()
        }
    },
    created() {
        this.getUsers();
    },
}
</script>
<style >
#table1.v-data-table .v-data-table-header th {
    background-color: #f3f6f9 !important;
    height: 50px !important;
    text-align: center !important;
    border-left: 1px solid #cccccc5e !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 17px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 40px !important;
    box-shadow: 0 6px 20px rgba(119, 119, 119, 0.15) !important;
    border-radius: 20px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #0d2c6d;
    /* border-left: 1px solid #cccccc5e !important; */
    text-align: center !important;
    border: none !important;
}

.text_fields {
    border: 1px solid #eee;
    border-radius: 13px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.table-spacing {
    height: 20px;
}
</style>
    