<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.seconds_left" type="number" hide-spin-buttons
                                    label="יתרה לניצול" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" style="padding-top: 20px !important;">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.status" outlined dense label="סטטוס">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="itemEdited.plan_id.type === 'postpaid' && isRenewExist" style="display: flex;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" medium style="margin-left: 10px;">
                                            mdi-help-circle
                                        </v-icon>
                                    </template>
                                    <span>שים לב, בשינוי הוק ללא פעיל הנך מבטל את ההו"ק בחברת האשראי</span>
                                </v-tooltip>
                                <v-select :items="renewTypeList" :item-text="'hebrewName'" :item-value="'value'"
                                    hide-details v-model="itemEdited.renew" outlined dense label="חידוש חבילה">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך פרטי חבילה' ? saveEditedItem() : saveNewItem()">
                                שמור
                            </v-btn>
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            seconds_left: "",
            status: "",
            renew: null,
        },
        isRenewExist: null,
        renewTypeList: [{ hebrewName: "פעיל", value: true }, { hebrewName: "לא פעיל", value: false }],
        status: [{ hebrewName: "פעיל", name: "active" }, { hebrewName: "לא פעיל", name: "inactive" }, { hebrewName: "הסתיים", name: "finished" }, { hebrewName: "פג תוקף", name: "expired" }],
    }),
    methods: {
        async saveEditedItem() {
            if (this.$refs.form.validate()) {

                const myJSON = JSON.stringify({ manuiPlan: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/manui_plans/edit_manui_plan";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", myJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        let message = "החבילה עודכנה בהצלחה";
                        if (this.isRenewExist && !this.itemEdited.renew) {
                            message = "החבילה עודכנה בהצלחה, ההוראת קבע בוטלה";
                        }
                        this.$emit("itemEdited", this.itemEdited, message, "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit manuiPlan: " + error, "red");

                }

            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך פרטי חבילה") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            this.isRenewExist = this.itemEdited.renew ? true : false;
        }
    },
}
</script>
<style></style>
  